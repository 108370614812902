const middleware = {}

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['aws-amplify-event-bus'] = require('../middleware/aws-amplify-event-bus.js')
middleware['aws-amplify-event-bus'] = middleware['aws-amplify-event-bus'].default || middleware['aws-amplify-event-bus']

middleware['load-data'] = require('../middleware/load-data.js')
middleware['load-data'] = middleware['load-data'].default || middleware['load-data']

middleware['onboarding-redirect'] = require('../middleware/onboarding-redirect.js')
middleware['onboarding-redirect'] = middleware['onboarding-redirect'].default || middleware['onboarding-redirect']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['vue-kindergarten'] = require('../middleware/vue-kindergarten.js')
middleware['vue-kindergarten'] = middleware['vue-kindergarten'].default || middleware['vue-kindergarten']

export default middleware
