// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f94247c1-bf1b-467b-8827-a0f0f694ae21",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_0JHTx0C5i",
    "aws_user_pools_web_client_id": "63q70tfars4i12del3tttfi7q3",
    "oauth": {}
};


export default awsmobile;
